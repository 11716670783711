import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Form from "../../../Common/Form/Form";
import Alert from "../../../Common/Alert";
import Toolbox from "../../../Common/Toolbox";

class AffiliateNetworks extends Component {

    static propTypes = {
        user: PropTypes.object.isRequired,
        refreshUser: PropTypes.func.isRequired,
        channel: PropTypes.object.isRequired,
        createInteraction: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            form: {},
            isUpdating: false,
            didUpdate: false,
            hasError: false,
            error: null,
            showInstructionsFor: null,
        };
    }

    handleFieldChange(name, value) {
        this.setState({
            form: {
                ...this.state.form,
                [name]: value
            },
            hasError: false,
            error: null,
        });
    }

    handleClickSave() {
        const {channel, user} = this.props;
        const {newKey, newValue} = this.state;
        const context = {...this.state.form, channel_id: channel.id};

        if (newKey && newValue) {
            context[newKey] = newValue;
        }

        this.setState({isUpdating: true, didUpdate: false});


        this.props.createInteraction(user.id, 'UpdateProfileFields', context)
            .then(resp => {
                if (resp.meta.error) {
                    this.setState({isUpdating: false, hasError: true, error: resp.meta.error})
                } else {
                    this.props.refreshUser(user.id);
                    this.setState({isUpdating: false, didUpdate: true});
                    setTimeout(() => this.setState({form: {}, didUpdate: false}), 2000);
                }
            });
    }

    getHelpFor(field) {
        const instructions = this.getInstructionsFor(field);
        const niceName = this.getNiceNameFor(field);
        if (!instructions) {
            return null;
        }
        return (
            <>Need help finding the right affiliate ID for {niceName}? <a href="#" rel="button" onClick={(e) => {this.setState({showInstructionsFor: field}); e.preventDefault(); return false; } }>View Instructions</a>.</>
        )

    }

    getNiceNameFor(field) {
        switch (field) {
            case 'affiliate.amazon':
                return 'Amazon Associates';
            case 'affiliate.shareasale':
                return 'ShareASale';
            case 'affiliate.cj':
                return 'CJ Affiliate';
            case 'affiliate.rakuten':
                return 'Rakuten Advertising';
            case 'affiliate.awin':
                return 'Awin';
            case 'affiliate.shopstyle':
                return 'ShopStyle Collective';
            case 'affiliate.ltk':
                return 'LIKEtoKNOW.it';
            case 'affiliate.impact':
                return 'Impact';
            case 'affiliate.walmart':
                return 'Walmart Affiliate';
            case 'affiliate.ascend':
                return 'Ascend';
            case 'affiliate.partnerize':
                return 'Partnerize';
            default:
                return 'Unknown';
        }
    }

    getInstructionsFor(field) {
        switch (field) {
            case 'affiliate.amazon':
                return [
                    <>Log in to your Amazon Associates account.</>,
                    <>Your <strong>Store ID</strong> (affiliate ID) is displayed in the top right corner of the dashboard.</>,
                    <>Alternatively, find it in the <strong>Account Settings</strong> under the <strong>Account Information</strong> section.</>
                ];
            case 'affiliate.shareasale':
                return [
                    <>Log in to your ShareASale account.</>,
                    <>Your <strong>Affiliate ID</strong> is displayed in the top left corner of the dashboard.</>,
                    <>Alternatively, find it in the <strong>Account Settings</strong>.</>
                ];
            case 'affiliate.cj':
                return [
                    <>Log in to your CJ Affiliate account.</>,
                    <>Navigate to <strong>Account > Websites</strong></>,
                    <>Your <strong>Publisher Web ID (PID)</strong> is listed next to each registered website.</>
                ];
            case 'affiliate.rakuten':
                return [
                    <>Log in to your Rakuten affiliate account.</>,
                    <>Go to <strong>Account Settings</strong>.</>,
                    <>Your <strong>Publisher ID</strong> is listed under the <strong>Account Info</strong> section.</>,
                ];
            case 'affiliate.awin':
                return [
                    <>Log in to your Awin account.</>,
                    <>On the dashboard, locate your <strong>Affiliate ID</strong> (under your account name or in the Account Details section).</>,
                ];
            case 'affiliate.shopstyle':
                return [
                    <>Log in to your ShopStyle Collective account.</>,
                    <>Navigate to <strong>Account Settings</strong>.</>,
                    <>Your User ID or <strong>Affiliate ID</strong> will be listed in your profile details.</>,
                ];
            case 'affiliate.ltk':
                return [
                    <>Log in to your LTK account.</>,
                    <>Navigate to the Profile Settings.</>,
                    <>Your Affiliate ID is displayed as part of your account details.</>,
                ];
            case 'affiliate.impact':
                return [
                    <>Log in to your Impact account.</>,
                    <>Your Affiliate ID is displayed in the top-left corner of the dashboard.</>,
                    <>Alternatively, check the Account Settings section.</>,
                ];
            case 'affiliate.walmart':
                return [
                    <>Log in to your Walmart Affiliate account.</>,
                    <>Navigate to Account Settings.</>,
                    <>Your Affiliate ID or Partner ID is listed in your profile information.</>,
                ];
            case 'affiliate.ascend':
                return [
                    <>Log in to your Ascend by Partnerize account.</>,
                    <>Navigate to Account > Account Settings.</>,
                    <>Look for your Publisher ID, which is your affiliate ID.</>,
                ];
            case 'affiliate.partnerize':
                return [
                    <>Log in to your Partnerize account.</>,
                    <>Navigate to Account Settings.</>,
                    <>Your Affiliate ID or Publisher ID will be listed in your account details.</>,
                ];

            default:
                return null;
        }
    }

    getFields() {
        return [
            {
                type: 'text',
                name: 'affiliate.amazon',
                title: 'Amazon Associates',
                placeholder: 'Enter your Amazon Associates Store or Affiliate ID',
                help: this.getHelpFor('affiliate.amazon'),
            },
            {
                type: 'text',
                name: 'affiliate.shareasale',
                title: 'ShareASale',
                placeholder: 'Enter your ShareASale Affiliate ID',
                help: this.getHelpFor('affiliate.shareasale'),
            },
            {
                type: 'text',
                name: 'affiliate.cj',
                title: 'CJ Affiliate (Formerly Commission Junction)',
                placeholder: 'Enter your Publisher Website ID (PID)',
                help: this.getHelpFor('affiliate.cj'),
            },
            {
                type: 'text',
                name: 'affiliate.rakuten',
                title: 'Rakuten Advertising',
                placeholder: 'Enter your Rakuten Publisher ID',
                help: this.getHelpFor('affiliate.rakuten'),
            },
            {
                type: 'text',
                name: 'affiliate.awin',
                title: 'Awin',
                placeholder: 'Enter your Awin Affiliate ID',
                help: this.getHelpFor('affiliate.awin'),
            },
            {
                type: 'text',
                name: 'affiliate.shopstyle',
                title: 'ShopStyle Collective',
                placeholder: 'Enter your ShopStyle Collective Affiliate ID',
                help: this.getHelpFor('affiliate.shopstyle'),
            },
            {
                type: 'text',
                name: 'affiliate.ltk',
                title: 'LIKEtoKNOW.it (Formerly RewardStyle)',
                placeholder: 'Enter your LTK Affiliate ID',
                help: this.getHelpFor('affiliate.ltk'),
            },
            {
                type: 'text',
                name: 'affiliate.impact',
                title: 'Impact',
                placeholder: 'Enter your Impact Affiliate ID',
                help: this.getHelpFor('affiliate.impact'),
            },
            {
                type: 'text',
                name: 'affiliate.walmart',
                title: 'Walmart Affiliate',
                placeholder: 'Enter your Walmart Affiliate ID or Partner ID',
                help: this.getHelpFor('affiliate.walmart'),
            },
            {
                type: 'text',
                name: 'affiliate.ascend',
                title: 'Ascend (Formerly Pepperjam)',
                placeholder: 'Enter your Ascend Publisher ID',
                help: this.getHelpFor('affiliate.ascend'),
            },
            {
                type: 'text',
                name: 'affiliate.partnerize',
                title: 'Partnerize (Formerly Performance Horizon)',
                placeholder: 'Enter your Partnerize Affiliate ID or Publisher ID',
                help: this.getHelpFor('affiliate.partnerize'),
            }
        ];
    }

    getFieldValues() {
        const {user, channel} = this.props;
        const {profile} = user || {};
        const formValues = this.state.form;
        let nativeValues = {};

        (profile || [])
            .filter(field => field.channel_id === channel.mongo_id)
            .forEach(customField => nativeValues[customField.key] = customField.value)

        return {...nativeValues, ...formValues};
    }

    renderSaveAlert() {
        let style = {
            position: 'sticky',
            top: 0,
            zIndex: 200,
        };

        if (this.state.isUpdating) {
            return <Alert
                classes={['info']} content={"Saving your changes..."} style={style}
            />
        }

        if (this.state.didUpdate) {
            return <Alert
                classes={['success']} content={"Changes saved!"} style={style}
            />
        }

        if (this.state.hasError) {
            return <Alert
                classes={['danger']} content={this.state.error} style={style}
            />
        }

        if (Object.keys(this.state.form).length > 0 || this.state.newKey || this.state.newValue) {
            return (
                <Alert
                    classes={['info']}
                    content={<span>You have unsaved changes. <a className="v3 bold" onClick={this.handleClickSave.bind(this)} role="button">Save now.</a></span>}
                    style={style}
                />
            );
        }
    }

    renderHelpPopup() {
        if (!this.state.showInstructionsFor) {
            return null;
        }
        const instructions = this.getInstructionsFor(this.state.showInstructionsFor);
        if (!instructions) {
            return null;
        }
        const Content = (
            <ul style={{padding: '30px 50px'}}>
                {instructions.map((instruction, i) => <li style={{marginBottom: 12}} key={i}>{instruction}</li>)}
            </ul>
        )
        const niceName = this.getNiceNameFor(this.state.showInstructionsFor);
        return (
            <Toolbox
                title={`Instructions for ${niceName}`}
                onClose={() => this.setState({showInstructionsFor: null})}
                content={Content}
                addlClasses={'toolbox-fixed toolbox-sm'}
                supportMobile={true}
            />
        )
    }
    render() {
        return (
            <div className="pane-inner-content">
                {this.renderSaveAlert()}
                {this.renderHelpPopup()}
                <p className='v3 light h7 pane-info'>Add your affiliate network accounts to track your earnings for applicable campaigns.</p>
                <Form
                    values={this.getFieldValues()}
                    onFieldChange={this.handleFieldChange.bind(this)}
                    fields={this.getFields()}
                />
            </div>
        );
    }

}

export default AffiliateNetworks;